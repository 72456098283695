import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import InfoIcon from '@material-ui/icons/Info';
import ContactsIcon from '@material-ui/icons/Contacts';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';

import {DATE_FORMAT} from '../../constants';
import renderSurveyMessages from "./SurveyDetails";
import renderMessages from "./MessageDetails";

import FileSaver from 'file-saver';

import {GroupIconName} from '../../components/GroupArtifacts';
import {getGroupMembers, clearCurrentGroupMembers} from '../../../redux/actions/groups';

const CampaignDetails = ({campaign}) => {
    const dispatch = useDispatch();
    const [currentDownloadGroupName, setCurrentDownloadGroupName] = useState(null);
    const {get_group_members_success, current_group_members} = useSelector(state => state.groups);

    useEffect(() => {
        if (get_group_members_success) {
            groupMembersToCsv();
        }
    }, [get_group_members_success]);

    const handleDownload = (group) => {
        setCurrentDownloadGroupName(group.group_name);
        dispatch(getGroupMembers(group.group_id));
    }

    const groupMembersToCsv = () => {
        let csvContentHeaders = new Set();
        current_group_members.forEach(member => {
            Object.getOwnPropertyNames(member).forEach(prop => {
                if (member[prop] !== null) {
                    if (prop === 'custom_attributes' && member[prop] !== null) {
                        Object.getOwnPropertyNames(member[prop]).forEach(subprop => csvContentHeaders.add(subprop));
                    } else if (member[prop]) {
                        csvContentHeaders.add(prop);
                    }
                }
            });
        });

        const csvContent = current_group_members.map(member => {
            let csv_content = [];
            csvContentHeaders.forEach(header => {
                if (member[header]) {
                    csv_content.push(`${member[header]}`);
                } else {
                    csv_content.push(`${member['custom_attributes'][header]}`);
                }

            });
            return csv_content.join(',');
        }).join('\n');

        csvContentHeaders = [...csvContentHeaders.values()].join(',');

        const csvData = new Blob([[csvContentHeaders, csvContent].join('\n')], {type: 'text/csv;charset=utf-8;'});
        FileSaver.saveAs(csvData, `${currentDownloadGroupName}.csv`);
        dispatch(clearCurrentGroupMembers());


    }

    const renderRecipients = () => {
        return campaign.groups.map(group => (
            <Chip onClick={handleDownload.bind(this, group)} key={group.group_id}
                  label={GroupIconName(group.group_name, group.type, 12)} variant="outlined" color="primary"/>
        ));
    };

    const renderTags = () => {
        return campaign.tags.map(tag => (
            <Chip key={tag} label={tag} variant="outlined" color="primary"/>
        ));
    }

    const renderLanguages = () => {
        return campaign.languages.map(language => (
            <Chip key={language} label={language} variant="outlined" color="primary"/>
        ));
    };

    return (
        <Container>

            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>{campaign.campaign_name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Tags</TableCell>
                        <TableCell>{renderTags()}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <VerticalCentered>
                                <Tooltip title="Click on a group to download the group data">
                                    <InfoIcon/>
                                </Tooltip>
                                <Box padding={1}>Recipients</Box>
                            </VerticalCentered>
                        </TableCell>
                        <TableCell>{renderRecipients()}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Schedule</TableCell>
                        <TableCell>{moment(campaign.trigger_datetime).format(DATE_FORMAT)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Template</TableCell>
                        <TableCell>{campaign.template}</TableCell>
                    </TableRow>
                    {campaign.languages !== undefined &&
                    <TableRow>
                        <TableCell>Languages</TableCell>
                        <TableCell>{renderLanguages()}</TableCell>
                    </TableRow>}
                    <TableRow>
                        <TableCell>Messages</TableCell>
                        <TableCell>{renderMessages(campaign)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Survey</TableCell>
                        <TableCell>{renderSurveyMessages(campaign)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Container>
    )

}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const VerticalCentered = styled.div`
	display: flex;
	align-items: center;
`;

export default CampaignDetails;
