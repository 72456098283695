import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import moment from 'moment';

import {DatePicker} from "@material-ui/pickers";
import {TimePicker} from "@material-ui/pickers";

import {setCampaignFormField} from '../../../../redux/actions/campaigns';

const DatetimeSelection = () => {
	const dispatch = useDispatch();
	const {trigger_datetime} = useSelector(state => state.campaigns.campaign_form);

	useEffect(() => {
		if(trigger_datetime === null) {
			const defaultDatetime = moment();
		dispatch(setCampaignFormField('trigger_datetime', defaultDatetime));
		}
	}, []);

	const handleDateChange = (value) => {
		const year = value.get('year');
		const month = value.get('month');
		const date = value.get('date');

		const newDatetime = trigger_datetime;

		newDatetime.set('year', year);
		newDatetime.set('month', month);
		newDatetime.set('date', date);

		dispatch(setCampaignFormField('trigger_datetime', newDatetime));
	}

	const handleTimeChange = (value) => {
		const hour = value.get('hour');
		const minute = value.get('minute');
		const second = value.get('second');

		const newDatetime = trigger_datetime;

		newDatetime.set('hour', hour);
		newDatetime.set('minute', minute);
		newDatetime.set('second', second);

		dispatch(setCampaignFormField('trigger_datetime', newDatetime));
	}

	return (
		<React.Fragment>
			<DatePicker
				label="Choose Date"
				value={trigger_datetime}
				onChange={handleDateChange}
				animateYearScrolling
			/>
			<TimePicker
				autoOk
				label="Choose Time"
				value={trigger_datetime}
				onChange={handleTimeChange}
			/>
		</React.Fragment>
	)
}

export default DatetimeSelection;