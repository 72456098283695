import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React from "react";
import Chip from "@material-ui/core/Chip";

const capitalise = (word) => {
    return word.charAt(0).toUpperCase() + word.substring(1)
};

const renderAnswerOptions = (options, language) => {

    return options.map((lang_options, index) => {
        return lang_options.map((option, inner_idx) => {
            if (option.language === language) {
                return (
                    <Chip key={index} label={option.value} variant="outlined" color="primary" />
                )
            }
        })

    });
};

const renderSurveyRatingOneLang = (survey_message, index) => {
    return survey_message.text.map((text, index_inner) => (
        <TableRow key={`${index}_${index_inner}`}>
            <TableCell>{text.value}</TableCell>
        </TableRow>

    ));

};

const renderSurveyRatingMessage = (survey_message, index) => {
    return survey_message.text.map((text, index_inner) => (
        <TableRow key={`${index}_${index_inner}`}>
            <TableCell>
                {capitalise(text.language)}
            </TableCell>
            <TableCell>{text.value}</TableCell>
        </TableRow>

    ));

};

const renderSurveyQuestionOneLang = (survey_message, index) => {
    return survey_message.text.map((text, index_inner) => (
        <TableRow key={`${index}_${index_inner}`}>
            <TableCell>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell><b>Question</b></TableCell>
                            <TableCell>{text.value}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><b>Answer Options</b></TableCell>
                            <TableCell>{renderAnswerOptions(survey_message.options, text.language)}</TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableCell>
        </TableRow>

    ));

};

const renderSurveyQuestionMessage = (survey_message, index) => {
    return survey_message.text.map((text, index_inner) => (
        <TableRow key={`${index}_${index_inner}`}>
            <TableCell>
                <ExpansionPanel style={{width: '100%'}}>


                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"

                    >
                        <Typography>{capitalise(text.language)}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell><b>Question</b></TableCell>
                                    <TableCell>{text.value}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><b>Answer Options</b></TableCell>
                                    <TableCell>{renderAnswerOptions(survey_message.options, text.language)}</TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </TableCell>
            {/*<TableCell>{survey_message.language[language_key]}</TableCell>*/}
        </TableRow>

    ));

};

const renderSurveyMessages = (campaign) => {
    return campaign.survey.survey_items.map((survey_message, index) => (
        <ExpansionPanel key={index}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>Survey Message {index + 1}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell><b>Message Type: {survey_message.rating ? "Rating Response" : "Question Response"}</b></TableCell>
                        </TableRow>
                        {renderSurveyMessage(survey_message.rating, campaign.languages, survey_message, index)}
                    </TableBody>
                </Table>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    ));

};


const renderSurveyMessage = (is_rating_message, languages, survey_message, index) => {
    if (languages.length > 1) {
        return is_rating_message ? renderSurveyRatingMessage(survey_message, index) : renderSurveyQuestionMessage(survey_message, index);
    } else {
        return is_rating_message ? renderSurveyRatingOneLang(survey_message, index) : renderSurveyQuestionOneLang(survey_message, index);;
    }
};

export default renderSurveyMessages;