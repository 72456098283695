import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import Button from '@material-ui/core/Button';

import CampaignDetails from '../components/CampaignDetails';
import {testCampaign} from '../../../redux/actions/campaigns';
import {formatTemplateVariables} from '../../../utils/template';

const ReviewCampaign = () => {
	const dispatch = useDispatch();
	const {campaign_form} = useSelector(state => state.campaigns);

	const campaign = {...campaign_form, template: formatTemplateVariables(campaign_form.template['template'], campaign_form.template['variables_value_mappings'])};

	return (
		<React.Fragment>
			<CampaignDetails campaign={campaign}/>

			<h4>If you wish to publish the campaign, please click confirm</h4>
		</React.Fragment>
	)
}

export default ReviewCampaign;