import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';

import MaterialTable from 'material-table';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Chip from '@material-ui/core/Chip';

import {
    getCampaigns,
    deleteCampaign,
    getCampaign,
    getCampaignForClone,
    clearCampaignCloneSuccess
} from '../../redux/actions/campaigns';

import {DATE_FORMAT} from '../constants';
import {clearLoadSpinner} from "../../redux/actions/auth";

const CampaignsTable = () => {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const {campaigns, get_campaigns_pending, set_campaign_clone_success} = useSelector(state => state.campaigns);

    const COLUMNS = [
        {
            title: 'Campaign',
            field: 'campaign_name',
            headerStyle: {fontWeight: "bold"}
        },
        {
            title: 'Tags',
            render: (rowData) => rowData.tags.map(tag => <Chip key={tag} label={tag} variant="outlined"
                                                               color="primary"/>),
            headerStyle: {fontWeight: "bold"}
        },
        {
            title: 'Schedule',
            field: 'trigger_datetime',
            headerStyle: {fontWeight: "bold"}
        },
        {
            title: 'Action',
            render: (rowData) => <ActionButton can_clone={rowData.can_clone} campaign_id={rowData.campaign_id}
                                               trigger_datetime={rowData.trigger_datetime}/>,
            headerStyle: {fontWeight: "bold"}
        }
    ];

    useEffect(() => {
        dispatch(getCampaigns());
    }, []);

    useEffect(() => {
        if (set_campaign_clone_success ===true){
            history.push(`/main/campaigns/create`);
            dispatch(clearCampaignCloneSuccess());
            dispatch(clearLoadSpinner())

        }
    }, [set_campaign_clone_success]);

    const processCampaigns = () => {
        const sorted = campaigns.sort((campaignA, campaignB) => {
            const dateA = new Date(campaignA.trigger_datetime);
            const dateB = new Date(campaignB.trigger_datetime);
            return dateB - dateA;
        });

        const formattedDates = sorted.map(
            campaign => ({...campaign, trigger_datetime: moment(campaign.trigger_datetime).format(DATE_FORMAT)})
        );

        return formattedDates;
    }

    return (
        <MaterialTable
            title="Campaigns"
            columns={COLUMNS}
            data={processCampaigns()}
            isLoading={get_campaigns_pending}
        />
    )
}

const ActionButton = ({can_clone, campaign_id, trigger_datetime}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleView = () => {
        history.push(`/main/campaigns/${campaign_id}`);
    }

    const handleClone = () => {
        handleClose();
        dispatch(getCampaignForClone(campaign_id));
    }

    const handleArchive = () => {
        handleClose();
        dispatch(deleteCampaign(campaign_id));
    }

    return (
        <React.Fragment>
            <IconButton onClick={handleClick}>
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleView}>View</MenuItem>
                <MenuItem
                    onClick={handleArchive}>{moment(trigger_datetime, DATE_FORMAT) > moment() ? 'Cancel' : 'Archive'}</MenuItem>
                {can_clone === true && <MenuItem onClick={handleClone}>Clone</MenuItem>}

            </Menu>
        </React.Fragment>
    )
}

const useStyles = makeStyles(theme => ({
    createdBy: {
        color: theme.palette.primary.main
    }
}));

export default CampaignsTable;