import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';

import {useTheme} from '@material-ui/core/styles'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';

import {setCampaignFormField} from '../../../../redux/actions/campaigns';
import {formatTemplateVariables} from '../../../../utils/template';

const TemplateSelection = () => {
	const dispatch = useDispatch();
	const {templates} = useSelector(state => state.configs);
	const selectedTemplate = useSelector(state => state.campaigns.campaign_form.template);

	const handleSelection = (template) => {
		dispatch(setCampaignFormField('template', template));
	}

	const renderTemplates = () => {
		return templates.map(template => {
			return (
				<Template
					key={template.id}
					template={template.id === selectedTemplate.id ? selectedTemplate : template}
					onComplete={handleSelection}
					isSelected={selectedTemplate.id === template.id}
				/>
			)
		});
	}

	return (
		<Container>
			{renderTemplates()}
		</Container>
	)
}

const Template = ({template, onComplete, isSelected}) => {
	const theme = useTheme();
	const [dialogOpen, setDialogOpen] = useState(false);

	const handleSelect = () => {
		if(template.variables) {
			setDialogOpen(true);
		}
		else {
			onComplete(template);
		}
	}

	const handleDialogCancel = () => {
		setDialogOpen(false);
	}

	const handleDialogComplete = (VariablesValueMappings) => {
		const newTemplate = {...template};
		newTemplate['variables_value_mappings'] = VariablesValueMappings;

		onComplete(newTemplate);
		setDialogOpen(false);
	}

	const renderVariablesInputDialog = () => {
		if(template.variables) {
			return (
				<TemplateVariableInputDialog
					variables={template.variables}
					open={dialogOpen}
					onCancel={handleDialogCancel}
					onComplete={handleDialogComplete}
				/>
			)
		}
	}

	const renderContent = () => {
		if(isSelected && template.variables) {
			return formatTemplateVariables(template.template, template['variables_value_mappings']);
		}
		else {
			return template.template;
		}
	}

	return (
		<React.Fragment>
			<Card onClick={handleSelect} style={isSelected ? {border: `1.5px solid ${theme.palette.primary.dark}`} : null}>
				<ButtonBase style={{height:'100%', width:'100%'}}>
				<CardContent>
					{renderContent()}
				</CardContent>
				</ButtonBase>
			</Card>
			{renderVariablesInputDialog()}
		</React.Fragment>
	)
}

const TemplateVariableInputDialog = ({variables, open, onCancel, onComplete}) => {
	const defaultFieldValues = variables.reduce((reduced, variable) => {
		reduced[variable] = '';
		return reduced;
	}, {});
	
	const [fields, setFields] = useState(defaultFieldValues);

	const handleCancel = () => {
		onCancel();
	}

	const handleComplete = () => {
		onComplete(fields);
	}

	const handleFieldChange = (event, field) => {
		const value = event.target.value;
		setFields({...fields, [field]: value});
	}

	const renderTextFields = () => {
		return variables.map(variable => {
			return (
				<TextField
					key={variable}
					label={variable}
					onChange={event => handleFieldChange(event, variable)}
					fullWidth
					margin="dense"
				/>
			)
		});
	}

	return (
		<Dialog open={open} fullWidth>
			<DialogContent style={{minHeight:"150px", display:"flex", flexDirection:"column", justifyContent:"center"}}>
				{renderTextFields()}
			</DialogContent>

			<DialogActions>
				<Button onClick={handleCancel} color="secondary">
					Cancel
				</Button>
				<Button onClick={handleComplete} color="primary">
					Complete
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-gap: 16px;
`;

export default TemplateSelection;