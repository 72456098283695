import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import CreateMessages from './components/CreateMessages';

const Messages = ({isValid}) => {
	const form_errors = useSelector(state => state.campaigns.form_errors);

	useEffect(() => {
		isValid(areMessagesValid());
	}, [form_errors]);

	const areMessagesValid = () => {
		let valid = true;

		if(form_errors['messages']) {
			const hasError = form_errors['messages'].some(errorObject => {
				if(errorObject.message_type) {
					return true;
				}
				if(errorObject.body) {
					return true;
				}
				if(errorObject.url) {
					return true;
				}

				const hasLanguageError = Object.keys(errorObject['language']).some(language => {
					if(errorObject['language'][language]) {
						return true;
					}
					else {
						return false;
					}
				});

				return hasLanguageError;
			});

			valid = !hasError;
		}

		return valid;
	}

	return (
		<CreateMessages />
	)
}

export default Messages;