import {
    SET_CAMPAIGN_FORM_FIELD,
    SET_CAMPAIGN_FORM_FIELD_ERROR,
    CLEAR_CAMPAIGN_FORM,
    CREATE_CAMPAIGN_PENDING,
    CREATE_CAMPAIGN_SUCCESS,
    CREATE_CAMPAIGN_FAILED,
    GET_CAMPAIGNS,
    GET_CAMPAIGNS_SUCCESS,
    GET_CAMPAIGNS_FAILED,
    SET_CAMPAIGNS,
    CLEAR_CAMPAIGNS,
    SET_CURRENT_CAMPAIGN,
    CLEAR_CURRENT_CAMPAIGN,
    SET_CAMPAIGN_STEP,
    INCREMENT_CAMPAIGN_STEP,
    DECREMENT_CAMPAIGN_STEP,
    SET_CAMPAIGN_FORM,
    SET_CAMPAIGN_FORM_ERRORS, SET_CAMPAIGN_FOR_CLONE_SUCCESS, CLEAR_CAMPAIGN_FOR_CLONE_SUCCESS,
    SET_CAMPAIGN_MEMBERS,
    CLEAR_CAMPAIGN_MEMBERS,
    SET_SURVEY_QUESTIONS
} from '../actions/campaigns';
import {v4 as uuidv4} from 'uuid';

const initState = {
    campaign_form: {
        campaign_name: '',
        trigger_datetime: null,
        template: {
            id: '',
            template: '',
            variables: [],
            variables_value_mappings: {}
        },
        languages: [],
        messages: [],
        groups: [],
        campaign_members: {},
        filter_groups: [],
        tags: [],
        survey: {survey_items: []},
        has_survey: false,
        submission_id: uuidv4()
    },
    form_errors: {},

    create_campaign_pending: false,
    create_campaign_success: false,
    create_campaign_failed: false,
    create_campaign_active_step: 0,

    get_campaigns_pending: false,
    get_campaigns_success: false,
    get_campaigns_failed: false,

    set_campaign_clone_success: false,

    campaigns: [],
    current_campaign: {},
};

const campaignsReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_CAMPAIGN_FORM_FIELD:
            return {...state, campaign_form: {...state.campaign_form, [action.payload.field]: action.payload.value}};
        case SET_CAMPAIGN_FORM_FIELD_ERROR:
            return {...state, form_errors: {...state.form_errors, ...action.payload}};
        case SET_CAMPAIGN_FORM_ERRORS:
            return {...state, form_errors: action.payload};
        case CLEAR_CAMPAIGN_FORM:
            return {
                ...state,
                campaign_form: {
                    ...initState.campaign_form,
                    campaign_members: {},
                    submission_id: uuidv4()
                },
                create_campaign_pending: false,
                create_campaign_success: false,
                create_campaign_failed: false,
                form_errors: {}
            };

        case SET_CAMPAIGN_FORM:
            const campaign_form_payload = {
                ...action.payload.campaign_form,
                trigger_datetime: null,
                campaign_name: "",
                submission_id: uuidv4()
            };
            return {...state, campaign_form: campaign_form_payload}

        case SET_CAMPAIGN_FOR_CLONE_SUCCESS:
            return {...state, set_campaign_clone_success: true};
        case CLEAR_CAMPAIGN_FOR_CLONE_SUCCESS:
            return {...state, set_campaign_clone_success: false};
        case SET_CAMPAIGN_STEP:
            return {...state, create_campaign_active_step: action.payload.count};
        case INCREMENT_CAMPAIGN_STEP:
            return {...state, create_campaign_active_step: state.create_campaign_active_step + 1};
        case DECREMENT_CAMPAIGN_STEP:
            return {...state, create_campaign_active_step: state.create_campaign_active_step - 1};
        case CREATE_CAMPAIGN_PENDING:
            return {...state, create_campaign_pending: true, create_campaign_success: false};
        case CREATE_CAMPAIGN_SUCCESS:
            return {...state, create_campaign_pending: false, create_campaign_success: true};
        case CREATE_CAMPAIGN_FAILED:
            return {...state, create_campaign_pending: false, create_campaign_failed: true};
        case GET_CAMPAIGNS:
            return {...state, get_campaigns_pending: true, get_campaigns_success: false, get_campaigns_failed: false};
        case GET_CAMPAIGNS_SUCCESS:
            return {...state, get_campaigns_pending: false, get_campaigns_success: true, get_campaigns_failed: false};
        case GET_CAMPAIGNS_FAILED:
            return {...state, get_campaigns_pending: false, get_campaigns_success: false, get_campaigns_failed: true};
        case SET_CAMPAIGNS:
            return {...state, campaigns: action.payload};
        case CLEAR_CAMPAIGNS:
            return {...state, campaigns: []};
        case SET_CURRENT_CAMPAIGN:
            return {...state, current_campaign: action.payload};
        case CLEAR_CURRENT_CAMPAIGN:
            return {...state, current_campaign: {}}
        case SET_CAMPAIGN_MEMBERS:
            return {
                ...state,
                campaign_form: {
                    ...state.campaign_form,
                    campaign_members: {
                        ...state.campaign_form.campaign_members,
                        [action.payload.group_id]: action.payload.members
                    }
                }
            };
        case CLEAR_CAMPAIGN_MEMBERS:
            return {...state, campaign_form: {...state.campaign_form, campaign_members: {}}};
        case SET_SURVEY_QUESTIONS:
            return {...state,
                campaign_form: {
                    ...state.campaign_form,
                    survey: {...state.campaign_form.survey, survey_items: action.payload.questions}
                }
            };
        default:
            return state;
    }
};

export default campaignsReducer;
