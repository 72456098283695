import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import {setCampaignFormField} from '../../../../redux/actions/campaigns';

const CampaignTagSelection = () => {
	const dispatch = useDispatch();
	const {campaign_tags} = useSelector(state => state.configs);
	const selectedTags = useSelector(state => state.campaigns.campaign_form.tags);

	const handleChange = (event, value) => {
		dispatch(setCampaignFormField('tags', value));
	}

	return (
		<Autocomplete
			onChange={handleChange}
			multiple
			id="checkboxes-groups"
			options={campaign_tags}
			disableCloseOnSelect
			getOptionLabel={tag => tag}
			value={selectedTags}
			renderOption={(tag, { selected }) => (
				<React.Fragment>
					<Checkbox
						icon={icon}
						checkedIcon={checkedIcon}
						style={{ marginRight: 8 }}
						checked={selected}
					/>
					{tag}
				</React.Fragment>
			)}
			renderInput={params => (
				<TextField {...params} variant="outlined" label="Select campaign tags" />
			)}
		/>
	)
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default CampaignTagSelection;