
import React from 'react';

import Grid from '@material-ui/core/Grid';
import ContactsIcon from '@material-ui/icons/Contacts';
import GroupIcon from '@material-ui/icons/Group';

import { GROUP_FILTER_TYPE, GROUP_TEMP_TYPE, GROUP_ARCHIVED_FILTER_TYPE } from '../constants';

const GroupIconName = (name, type, icon_size = 24) => {
    return (
        <Grid container direction='row' alignItems='center' spacing={1}>
            {(type === GROUP_FILTER_TYPE || type === GROUP_ARCHIVED_FILTER_TYPE) &&
                <Grid item>
                    <ContactsIcon style={{ fontSize: icon_size }} />
                </Grid>
            }
            {type === GROUP_TEMP_TYPE &&
                <Grid item>
                    <GroupIcon style={{ fontSize: icon_size }} />
                </Grid>
            }
            <Grid item>
                {name}
            </Grid>
        </Grid>
    )
}

export { GroupIconName };