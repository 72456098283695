import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Fab from '@material-ui/core/Fab';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import cloneDeep from 'lodash.clonedeep';

import { setCampaignFormField, setSurveyQuestions } from '../../../../redux/actions/campaigns';
import {MAX_SURVEY_QUESTIONS} from "../../../../configs";

const CreateSurvey = () => {
	const dispatch = useDispatch();
	const { survey_items } = useSelector(state => state.campaigns.campaign_form.survey);
	const languages  = useSelector(state => state.campaigns.campaign_form.languages);
	const { survey } = useSelector(state => state.campaigns.campaign_form);

	const multilang = languages.length > 1;

	const defaultOption = languages.reduce((reduced, language) => {
		reduced.push({ language: language, value: '' });
			return reduced;
	}, []);

	const defaultSurveyQuestion = () => {
		return cloneDeep({
			rating: false,
			text: languages.reduce((reduced, language) => {
				reduced.push({ language: language, value: '' });
				return reduced;
			}, []),
			options: [[...defaultOption], [...defaultOption]]
		})
	}

	useEffect(() => {
		if (survey_items.length === 0) {
			const newSurveyItems = [defaultSurveyQuestion()];
			dispatch(setSurveyQuestions(newSurveyItems));
		}
	}, [survey_items]);

	const addSurveyQuestion = () => {
		const newSurveyItems = cloneDeep(survey_items);
		newSurveyItems.push(defaultSurveyQuestion());
		dispatch(setSurveyQuestions(newSurveyItems));
	}

	const handleQuestionChange = (index, question) => {
		const newSurveyItems = cloneDeep(survey_items);
		newSurveyItems[index] = question;
		dispatch(setSurveyQuestions(newSurveyItems));
	}

	const handleRemoveQuestion = (index) => {
		const newSurveyItems = cloneDeep(survey_items);
		newSurveyItems.splice(index, 1);

		dispatch(setSurveyQuestions(newSurveyItems));
	}

	const renderSurveyQuestions = () => {
		return survey_items.map((surveyQuestion, index) => {
			return (
				<div key={index} style={{position: 'relative'}}>
					<Fab
						onClick={() => handleRemoveQuestion(index)}
						size="small"
						color="secondary"
						style={{position: 'absolute', top: '-20px', right: '-20px'}}
						disabled={survey_items.length === 1}
					>
						<ClearIcon />
					</Fab>
					<SurveyQuestion multilang={multilang} question={surveyQuestion} onChange={(question) => handleQuestionChange(index, question)} />
				</div>
			)
		});
	}

	return (
		<Container>
			<Button
				color="primary"
				variant="contained"
				onClick={addSurveyQuestion}
				disabled={survey_items.length >= MAX_SURVEY_QUESTIONS}
				style={{marginBottom: '16px'}}
			>
				Add Question
			</Button>
			{renderSurveyQuestions()}
		</Container>
	)
}

const SurveyQuestion = ({ multilang, question, onChange }) => {

	const addOption = () => {
		const newOption = question.text.reduce((reduced, ques) => { reduced.push({ language: ques.language, value: '' }); return reduced }, []);

		onChange({...question, options: [...question.options, newOption]});
	}

	const handleQuestionTextChange = (event, lang_index) => {
		question.text[lang_index].value = event.target.value
		onChange({ ...question, text: question.text });
	}

	const handleOptionChange = (index, option) => {
		const newQuestion = cloneDeep(question);
		newQuestion.options[index] = option;
		onChange(newQuestion);
	}

	const handleRatingChange = (event, checked) => {
		const newQuestion = cloneDeep(question);
		newQuestion.rating = checked;
		onChange(newQuestion);
	}
	
	const handleRemoveOption = (index) => {
		const newQuestion = cloneDeep(question);
		newQuestion.options.splice(index, 1)
		onChange(newQuestion);
	}

	const renderQuestionTextFields = (multilang) => {
		return question.text.map((ques, lang_index) => {
			return (
				<TextField
					key={lang_index}
					value={ques.value}
					label={multilang ? `Question in ${ques.language}` : 'Question'}
					variant="outlined"
					onChange={(event) => handleQuestionTextChange(event, lang_index)}
				/>
			)
		});
	}

	const renderQuestionOptions = () => {
		if(!question.rating) {
			return question.options.map((option, index) => {
				return (
					<div key={index} style={{display: 'grid', gridTemplateColumns: '1fr auto'}}>
						<SurveyQuestionOptions multilang={multilang} option={option} onChange={(newOption) => handleOptionChange(index, newOption)} />

						<IconButton aria-label="delete" onClick={() => handleRemoveOption(index)} disabled={question.options.length === 2}>
							<DeleteIcon fontSize="small" />
						</IconButton>
					</div>
				)
			});
		}
	}

	const renderAddOptionButton = () => {
		if(!question.rating) {
			return <Button color="primary" variant="contained" onClick={addOption} disabled={question.options.length >= 5}>Add Option</Button>
		}
	}

	return (
		<QuestionContainer>
			{renderQuestionTextFields(multilang)}

			<OptionsControllerContainer>
				<FormControlLabel
					control={<Checkbox checked={question.rating} onChange={handleRatingChange} />}
					label="Rating (1 - Strongly disagree, 2 - Somewhat disagree, 3 - Neither agree nor disagree, 4 - Somewhat agree, 5 - Strongly agree)"
				/>
				{renderAddOptionButton()}
			</OptionsControllerContainer>

			{renderQuestionOptions()}

		</QuestionContainer>
	)
}

const SurveyQuestionOptions = ({ multilang, option, onChange }) => {

	const handleOptionTextChange = (event, lang_index) => {
		const newOption = cloneDeep(option);
		newOption[lang_index].value = event.target.value;
		onChange(newOption);
	}

	const renderOptionTextFields = (multilang) => {
		return option.map((lang_option, lang_index) => {			
			return (
				<TextField
					key={lang_index}
					value={lang_option.value}
					label={multilang ? `Option in ${lang_option.language}` : 'Option'}
					variant="outlined"
					onChange={(event) => handleOptionTextChange(event, lang_index)}
				/>
			)
		});
	}

	return (
		<OptionsContainer columns={option.length}>
			{renderOptionTextFields(multilang)}
		</OptionsContainer>
	)
}

const Container = styled.div`
`;

const QuestionContainer = styled.div`
	display: grid;
	grid-gap: 16px;
	border: 1px solid;
	border-radius: 4px;
	margin-bottom: 24px;
	padding: 16px;
`;

const OptionsContainer = styled.div`
	display: grid;
	grid-column-gap: 8px;
	grid-template-columns: repeat(${props => props.columns || 1}, 1fr);
`;

const OptionsControllerContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;


export default CreateSurvey;