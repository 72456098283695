import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import MaterialTable from 'material-table';

import { getFilterMembers } from '../../../redux/actions/groups';
import { setCampaignFormField, setCampaignMembers } from '../../../redux/actions/campaigns';

const FilterContacts = (filterGroup) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { filter_members, get_filter_members_success, get_filter_members_pending } = useSelector(state => state.groups);
    const [members, setMembers] = useState([]);
    const [member_attributes, setMemberAttributes] = useState([]);
    
    useEffect(() => {
        dispatch(getFilterMembers(filterGroup));
    }, []);

    useEffect(() => {
        if (get_filter_members_success) {
            const flattened = filter_members.map(members => flattenObject(members));
            const row = flattened.reduce((acc, entry) => {
                if (entry.length > acc.length) {
                    acc = entry
                }; return acc
                }, flattened[0]);
            setMemberAttributes(Object.keys(row).map((attr) => { return { title: toTitle(attr), field: attr } }));
            setMembers(flattened);
        }
    }, [get_filter_members_success]);

    const handleSelection = (selection) => {
        if (selection) {
            const campaign_members = [];
            selection.forEach((member) =>
                campaign_members.push({
                    'user_id': member.user_id,
                    'first_name': member.first_name,
                    'last_name': member.last_name
                })
            );
            dispatch(setCampaignMembers(campaign_members, filterGroup.group_id));
        }
    }

    return (
        <MaterialTable
            title={`Filter Contacts: ${filterGroup.group_name}`}
            columns={member_attributes}
            data={members}
            isLoading={get_filter_members_pending}
            options={{
                selection: true,
                filtering: true
            }}
            onSelectionChange={handleSelection}
        />
    )
}

const useStyles = makeStyles(theme => ({
    createdBy: {
        color: theme.palette.primary.main
    }
}));

const flattenObject = (obj) => {
    const flattened = {}

    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            Object.assign(flattened, flattenObject(obj[key]))
        } else {
            flattened[key] = obj[key]
        }
    })

    return flattened
}

function toTitle(str) {
    if (str === 'user_id') {
        return 'Contact Number';
    }
    const to_title = str.replace(
        /[a-z]+/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
    return to_title.replace(/_+/g, ' ');
}

export default FilterContacts;