import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import InfoIcon from '@material-ui/icons/Info';
import ContactsIcon from '@material-ui/icons/Contacts';
import Box from '@material-ui/core/Box';

import CampaignNameInput from './components/CampaignNameInput';
import CampaignTagSelection from './components/CampaignTagSelection';
import GroupSelection from './components/GroupSelection';

const NameAndRecipients = ({isValid}) => {
	const {form_errors} = useSelector(state => state.campaigns);

	useEffect(() => {
		let valid = true;

		if( !(form_errors['campaign_name'] === false) || !(form_errors['tags'] === false) || !(form_errors['groups'] === false) )  {
			valid = false;
		}

		isValid(valid);
	}, [form_errors]);

	return (
		<Container>
			<VerticalCentered><InfoIcon /><Box padding={1}>Please ensure media files are in the required format</Box></VerticalCentered>
			<VerticalCentered><InfoIcon /><Box padding={1}>Please ensure all groups are up to date</Box></VerticalCentered>
			<VerticalCentered><InfoIcon /><Box padding={1}>Contact Groups are indicated by the <ContactsIcon style={{ fontSize: 12 }} /> Icon</Box></VerticalCentered>

			<CampaignNameInput />
			<CampaignTagSelection />
			<GroupSelection />
		</Container>
	)
}

const Container = styled.div`
	display: grid;
	grid-row-gap: 16px;
`;

const VerticalCentered = styled.div`
	display: flex;
	align-items: center;
`;

export default NameAndRecipients;