import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import axios from "axios";

import LinearProgress from '@material-ui/core/LinearProgress';
import CampaignDetails from './components/CampaignDetails';

import {getCampaign} from '../../redux/actions/campaigns';

import {API_ROOT_URL} from '../../configs';


const ViewCampaign = () => {
	const dispatch = useDispatch();
	const {campaign_id} = useParams();
	const {current_campaign} = useSelector(state => state.campaigns);
	const {user} = useSelector(state => state.auth);
	const [groupDetails, setGroupDetails] = useState([]);
	const campaign = current_campaign;

	useEffect(() => {
		dispatch(getCampaign(campaign_id));
	}, []);

	useEffect(() => {
		if(!(Object.keys(current_campaign).length === 0)) {
			getAllGroupDetails();
		}
	},[current_campaign]);

	const getGroupDetails = (groupId) => {
		const headers = {
			'Content-Type': 'application/json',
			'authorization': user.token
		};
		const url = `${API_ROOT_URL}/group/${groupId}`;

		return axios.get(url, {headers});
	}

	const getAllGroupDetails = () => {
		Promise.all(
			current_campaign.groups.map(groupId => {
				return getGroupDetails(groupId)
			})
		).then(responses => {
			setGroupDetails(responses.map(({data}) => data));
		});
	}


	const render = () => {
		if(!(Object.keys(current_campaign).length === 0) && groupDetails.length > 0) {
			const campaign = {...current_campaign, template: current_campaign.template_message, groups: groupDetails};

			return <CampaignDetails campaign={campaign}/>
		}
		else {
			return <LinearProgress />
		}
	}

	return (
		<React.Fragment>
			{render()}
		</React.Fragment>
	)
}

export default ViewCampaign;