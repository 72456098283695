import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileSaver from 'file-saver';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import MaterialTable from 'material-table';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { getGroups, getGroupMembers, clearCurrentGroupMembers, archiveGroup, unarchiveGroup } from '../../redux/actions/groups';

import { DATE_FORMAT } from '../constants';
import { GroupIconName } from '../components/GroupArtifacts'

const GroupsTable = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [archiveVisibility, setArchiveVisibility] = useState(false)
	const [currentDownloadGroupName, setCurrentDownloadGroupName] = useState(null);
	const { groups, get_group_members_success, current_group_members, get_groups_pending } = useSelector(state => state.groups);

	const handleDownload = (group_id, group_name) => {
		setCurrentDownloadGroupName(group_name);
		dispatch(getGroupMembers(group_id));
	}

	const COLUMNS = [
		{
			title: 'Group',
			render: (rowData) => GroupIconName(rowData.group_name, rowData.type),
			field: 'group_name',
			headerStyle: { fontWeight: "bold" }
		},
		{
			title: 'Created at',
			field: 'created_on',
			headerStyle: { fontWeight: "bold" }
		},
		{
			title: 'Actions',
			render: (rowData) => <ActionButton
				group_id={rowData.group_id}
				group_name={rowData.group_name}
				archived={rowData.type === 'ARCHIVED_SIMPLE' || rowData.type === 'ARCHIVED_FILTER'}
				handleDownloadFunc={handleDownload}
				view_archived={archiveVisibility} />,
			headerStyle: { fontWeight: "bold" }
		}
	];

	useEffect(() => {
		dispatch(getGroups(archiveVisibility));
	}, []);

	useEffect(() => {
		if (get_group_members_success) {
			groupMembersToCsv();
		}
	}, [get_group_members_success]);


	const handleVisibilityToggle = (state) => {
		setArchiveVisibility(state);
		dispatch(getGroups(state));
	}

	const groupMembersToCsv = () => {
		let csvContentHeaders = new Set();
		current_group_members.forEach(member => {
			Object.getOwnPropertyNames(member).forEach(prop => {
				if (member[prop] !== null) {
					if (prop === 'custom_attributes') {
					Object.getOwnPropertyNames(member[prop]).forEach(subprop => csvContentHeaders.add(subprop));
				} else {
					csvContentHeaders.add(prop);
					}
				}
			});
		});

		const csvContent = current_group_members.map(member => {
			let csv_content = [];
			csvContentHeaders.forEach(header => {
				if (member[header]) {
					csv_content.push(`${member[header]}`);
				} else {
					csv_content.push(`${member['custom_attributes'][header]}`);
				}

			});
			return csv_content.join(',');
		}).join('\n');

		csvContentHeaders = [...csvContentHeaders.values()].join(',');

		const csvData = new Blob([[csvContentHeaders, csvContent].join('\n')], { type: 'text/csv;charset=utf-8;' });
		FileSaver.saveAs(csvData, `${currentDownloadGroupName}.csv`);
		dispatch(clearCurrentGroupMembers());


	}


	const processGroups = () => {
		const sorted = groups.sort((groupA, groupB) => {
			const dateA = new Date(groupA.created_on);
			const dateB = new Date(groupB.created_on);
			return dateB - dateA;
		});

		const formattedDates = sorted.map(
			group => ({ ...group, created_on: moment(group.created_on).format(DATE_FORMAT) })
		);

		return formattedDates;
	}

	return (
		<MaterialTable
			title="Groups"
			columns={COLUMNS}
			data={processGroups()}
			isLoading={get_groups_pending}
			actions={[
				{
					icon: () => <VisibilityOffIcon />,
					hidden: archiveVisibility,
					tooltip: 'Show Archived',
					isFreeAction: true,
					onClick: () => handleVisibilityToggle(true)
				},
				{
					icon: () => <VisibilityIcon />,
					hidden: !archiveVisibility,
					tooltip: 'Show Active',
					isFreeAction: true,
					onClick: () => handleVisibilityToggle(false)
				}
			]}
		/>
	)
}

const ActionButton = ({ group_id, group_name, archived, handleDownloadFunc, view_archived }) => {
	const dispatch = useDispatch();

	const [anchorEl, setAnchorEl] = useState(null);


	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	}

	const handleClose = () => {
		setAnchorEl(null);
	}

	const handleArchive = () => {
		handleClose();
		dispatch(archiveGroup(group_id, view_archived));
	}

	const handleUnarchive = () => {
		handleClose();
		dispatch(unarchiveGroup(group_id, view_archived));
	}

	const handleDownload = () => {
		handleClose();
		handleDownloadFunc(group_id, group_name);
	}

	return (
		<React.Fragment>
			<IconButton onClick={handleClick}>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem onClick={handleDownload}>Download</MenuItem>
				{archived ?
					<MenuItem onClick={handleUnarchive}>Unarchive</MenuItem>
					:
					<MenuItem onClick={handleArchive}>Archive</MenuItem>
				}
			</Menu>
		</React.Fragment>
	)
}

const useStyles = makeStyles(theme => ({
	createdBy: {
		color: theme.palette.primary.main
	}
}));

export default GroupsTable;