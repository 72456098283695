import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import CSVReader from "react-csv-reader";

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import ContactsIcon from '@material-ui/icons/Contacts';
import Box from '@material-ui/core/Box';

import { normalizeTelephoneNumber } from '../../utils/phone-number';

import { setGroupFormField, createGroup, clearGroupForm } from '../../redux/actions/groups';
import { notistackError } from '../../redux/actions/notistack';


const CreateGroup = () => {
	const dispatch = useDispatch();
	const { name, members } = useSelector(state => state.groups.group_form);
	const { create_group_pending, create_group_success, form_errors } = useSelector(state => state.groups);
	const [formValid, setFormValid] = useState(false);
	// Does not seem like there is an easy way to reset file input field, use this to rerender input field 
	const [inputFieldKey, setInputFieldKey] = useState('');

	useEffect(() => {
		const valid = (form_errors['name'] === false) && (form_errors['members'] === false);
		setFormValid(valid);
	}, [form_errors]);

	useEffect(() => {
		if (create_group_success) {
			dispatch(clearGroupForm());
		}
	}, [create_group_success]);

	const handleNameChange = (event) => {
		const value = event.target.value;
		dispatch(setGroupFormField('name', value));
	}

	const handleCsvFileLoaded = (data, fileInfo) => {
		const users = data.map(details => {
			const first_name = details.name;
			delete details.name;
			const last_name = details.surname;
			delete details.surname
			const user_id = normalizeTelephoneNumber(details.contact_number);
			delete details.contact_number
			const custom_attributes = details

			return { first_name, last_name, user_id, custom_attributes };
		});

		if (areUsersValid(users)) {
			dispatch(setGroupFormField('members', users));
		}
	}

	const handleSubmit = () => {
		dispatch(createGroup({ group_name: name, members }));
	}

	const areUsersValid = (users) => {
		if (users.length === 0) {
			return false;
		}

		const invalid = users.some((user, index) => {
			
			const num_custom_attrs = Object.keys(user.custom_attributes).length
			if (num_custom_attrs > 30) {
				setInputFieldKey(new Date());
				dispatch(notistackError(`Too many custom attributes: ${num_custom_attrs}`));
				return true;
			}

			if (user.first_name && user.last_name && user.user_id) {
				return false;
			}
			else {
				console.log(user);
				setInputFieldKey(new Date());
				dispatch(notistackError(`CSV file error at line ${index + 2}`));
				return true;
			}
		});

		return !invalid;
	}

	return (
		<Container>
			<VerticalCentered><InfoIcon /><Box padding={1}> If you would like to create a Simple Group ensure the CSV file contains only the following 3 columns: <Box display="inline" fontWeight="bold">Name, Surname, Contact number</Box>, including a header row.</Box></VerticalCentered>
			<VerticalCentered><InfoIcon /><Box padding={1}> If you would like to create a <ContactsIcon style={{ fontSize: 12 }} /> Contact Group ensure the CSV file contains the following columns: <Box display="inline" fontWeight="bold">Name, Surname, Contact number</Box> (including a header row), and at least one custom field (<Box display="inline" fontWeight="bold">There is a max of 30 custom fields</Box>). Futher fields can be added with any names e.g. <Box display="inline" fontWeight="bold">Division, Facility, etc..</Box> (this name must also be included in the header row)</Box></VerticalCentered>
			<TextField value={name} onChange={handleNameChange} label="Group Name" variant="outlined" />

			<CSVReader
				label="Upload CSV file: "
				parserOptions={{
					header: true,
					transformHeader: (header) => { return header.trim().replace(/\s/g, "_").toLowerCase() },
					transform: (value) => { return value.trim() },
					skipEmptyLines: true
				}}
				onFileLoaded={handleCsvFileLoaded}
				key={inputFieldKey}
			/>

			<ButtonContainer>
				<Button variant="contained" color="primary" onClick={handleSubmit} disabled={!formValid || create_group_pending}>
					Submit
				</Button>
			</ButtonContainer>

		</Container>
	)
}

const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 16px;
	padding: 48px;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const VerticalCentered = styled.div`
	display: flex;
	align-items: center;
`;

export default CreateGroup;
