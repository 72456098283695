import {
    apiRequest,
    API_POST,
    API_GET,
    API_DELETE
} from '../../actions/api';

import {
    CREATE_CAMPAIGN,
    GET_CAMPAIGNS,
    GET_CAMPAIGN,
    SET_CAMPAIGN_FORM_FIELD,
    TEST_CAMPAIGN,
    DELETE_CAMPAIGN,
    SET_CAMPAIGN_MEMBERS,
    setCreateCampaignPending,
    setCreateCampaignSuccess,
    setCampaigns,
    setCampaignFormField,
    clearCampaignMembers, clearCampaigns,
    clearCurrentCampaign,
    clearFilterMembers,
    setCurrentCampaign,
    setFormFieldError,
    setCreateCampaignFailed,
    setTestCampaignRequested,
    getCampaigns,
    getCampaignsSuccess,
    getCampaignsFailed,
    GET_CAMPAIGN_FOR_CLONE,
    setCampaignForClone,
    SET_CAMPAIGN_FOR_CLONE,
    setCampaignStep,
    setCampaignForm,
    setFormErrors,
    setCampaignCloneSuccess, SET_SURVEY_QUESTIONS

} from '../../actions/campaigns';

import {validateField, requiredCheck, minArrayLengthCheck, messagesCheck, surveyCheck} from '../../../utils/validators';
import {notistackError, notistackSuccess} from "../../actions/notistack";
import {setLoadSpinner} from "../../actions/auth";

const validationSettings = {
    campaign_name: [requiredCheck],
    trigger_datetime: [requiredCheck],
    template: [requiredCheck],
    messages: [(value) => minArrayLengthCheck(1, value), messagesCheck],
    groups: [(value) => minArrayLengthCheck(1, value)],
    tags: [(value) => minArrayLengthCheck(1, value)],
    survey: [(value) => minArrayLengthCheck(1, value), surveyCheck],
    has_survey: [requiredCheck],
};


const campaigns = ({dispatch, getState}) => next => action => {

    next(action);

    switch (action.type) {
        case CREATE_CAMPAIGN:
            next(setCreateCampaignPending());
            next(
                apiRequest({
                    path: 'campaign',
                    method: API_POST,
                    data: action.payload,
                    success: () => {
                        next(notistackSuccess('Campaign created'));
                        return setCreateCampaignSuccess()
                    },
                    failure: (error) => {
                        console.log(error)
                        if (error?.response?.status === 409) {
                            next(notistackError('Campaign already created!'));
                        } else {
                            next(notistackError('Failed to create campaign'));
                        }
                        return setCreateCampaignFailed();
                    }
                })
            );
            break;
        case GET_CAMPAIGNS:
            next(clearCampaigns());
            next(
                apiRequest({
                    path: 'campaign',
                    method: API_GET,
                    success: (data) => {
                        next(setCampaigns(data));
                        return getCampaignsSuccess();
                    },
                    failure: getCampaignsFailed
                })
            );
            break;
        case GET_CAMPAIGN:
            const {campaign_id} = action.payload;
            next(clearCurrentCampaign());
            next(
                apiRequest({
                    path: `campaign/${campaign_id}`,
                    method: API_GET,
                    success: setCurrentCampaign
                })
            );
            break;

        case GET_CAMPAIGN_FOR_CLONE:
            next(setLoadSpinner())
            next(
                apiRequest({
                    path: `campaign-form/${action.payload.campaign_id}`,
                    method: API_GET,
                    success: setCampaignForClone
                })
            );
            break;

        case SET_CAMPAIGN_FOR_CLONE:
            console.log(action.payload)
            next(setCampaignForm(action.payload))
            next(setFormErrors({
                tags: false,
                filter_groups: false,
                groups: false,
                template: false,
                languages: false
                // messages: action.payload.messages.map((value, index) => ({"message_type": false, "body": false}))
            }))
            next(setCampaignStep(0));
            next(setCampaignCloneSuccess())
            break;
        case SET_CAMPAIGN_FORM_FIELD:
            const field = action.payload.field;
            const value = action.payload.value;

            let val = value;
            switch (field) {
                case 'template': {
                    val = value.template;
                    break;
                }
                case 'filter_groups': {
                    next(clearCampaignMembers());
                    break;
                }

                default: {
                    break;
                }

            }

            const error = validateField(field, val, validationSettings);
            next(setFormFieldError(error));
            break;
        case TEST_CAMPAIGN:
            next(
                apiRequest({
                    path: 'test_campaign',
                    method: API_POST,
                    data: action.payload,
                    success: () => {
                        next(notistackSuccess('Test campaign requested'))
                        return setTestCampaignRequested();
                    }
                })
            );
            break;
        case SET_SURVEY_QUESTIONS: {
            const error = validateField('survey', action.payload.questions, validationSettings);
            next(setFormFieldError(error));
            break;
        }
        case DELETE_CAMPAIGN: {
            const {campaign_id} = action.payload;
            next(
                apiRequest({
                    path: `campaign/${campaign_id}`,
                    method: API_DELETE,
                    success: getCampaigns
                })
            );
            break;
        }
        default:
            break;
    }
};

export default campaigns;