import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { GroupIconName } from '../../../components/GroupArtifacts';

import {setCampaignFormField} from '../../../../redux/actions/campaigns';

import { GROUP_FILTER_TYPE } from '../../../constants';

const GroupSelection = () => {
	const dispatch = useDispatch();
	const {groups} = useSelector(state => state.groups);
	const selectedGroups = useSelector(state => state.campaigns.campaign_form.groups);

	const handleChange = (event, groups) => {
		const filter_groups = groups.filter(group => group.type === GROUP_FILTER_TYPE)
		dispatch(setCampaignFormField('filter_groups', filter_groups));

		dispatch(setCampaignFormField('groups', groups));
	};

	const sortedGroups = () => {
		const sorted = groups.sort((groupA, groupB) => {
			const dateA = new Date(groupA.created_on);
			const dateB = new Date(groupB.created_on);
			return dateB - dateA;
		});

		return sorted;
	};

	return (
		<Autocomplete
			onChange={handleChange}
			multiple
			id="checkboxes-tags-demo"
			options={sortedGroups()}
			disableCloseOnSelect
			getOptionLabel={group => GroupIconName(group.group_name, group.type, 12)}
			value={selectedGroups}
			renderOption={(group, { selected }) => (
				<React.Fragment>
					<Checkbox
						icon={icon}
						checkedIcon={checkedIcon}
						style={{ marginRight: 8 }}
						checked={selected}
					/>
					{GroupIconName(group.group_name, group.type)}
				</React.Fragment>
			)}
			renderInput={params => (
				<TextField {...params} variant="outlined" label="Select contact groups" />
			)}
		/>
	)
}


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default GroupSelection;